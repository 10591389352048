<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("payments.new") }}</TitleBar>
        <TitleBar v-else
                  :disabled="loading"
                  :loading="loading"
                  :menu="[{text: this.$t('payments.delete'), callback: deleteElement, class: 'red--text'}]"
        >{{ $t("payments.edit") }}
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">

            <v-row>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="contact"
                        :disabled="loading || this.$route.params.uuid !== 'new'"
                        :error-messages="contactErrors"
                        :items="contacts"
                        :label="$t('payments.contact')"
                        :no-data-text="$t('no_data')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.contact.$touch()"
                        @input="$v.contact.$touch()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="category"
                        :disabled="loading"
                        :error-messages="categoryErrors"
                        :items="categories"
                        :label="$t('payments.category')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.category.$touch()"
                        @input="$v.category.$touch()"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="account"
                        :disabled="loading"
                        :error-messages="accountErrors"
                        :items="accounts"
                        :label="$t('payments.account')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.account.$touch()"
                        @input="$v.account.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="amount"
                        :disabled="loading"
                        :error-messages="amountErrors"
                        :label="$t('payments.amount')"
                        :suffix="currency_code"
                        outlined
                        @blur="$v.amount.$touch()"
                        @input="$v.amount.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency_code"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('payments.currency')"
                        outlined
                        @blur="$v.currency_code.$touch()"
                        @input="$v.currency_code.$touch()"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="currency_rate"
                        :disabled="loading"
                        :label="$t('payments.currency_rate')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <DatePicker
                        v-model="date"
                        :disabled="loading"
                        :error-messages="dateErrors"
                        :label="$t('payments.date')"
                        @blur="$v.date.$touch()"
                        @input="$v.date.$touch()"
                    ></DatePicker>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="payment_method"
                        :disabled="loading"
                        :error-messages="paymentMethodErrors"
                        :label="$t('payments.payment_method')"
                        outlined
                        @blur="$v.payment_method.$touch()"
                        @input="$v.payment_method.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="reference"
                        :disabled="loading"
                        :label="$t('payments.reference')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-textarea
                v-model="description"
                :disabled="loading"
                :label="$t('payments.description')"
                auto-grow
                outlined
                rows="3"
            ></v-textarea>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("payments.add") : $t("payments.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->
        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

        <!--        <v-btn-->
        <!--            elevation="2"-->
        <!--            color="error"-->
        <!--            :loading="loading"-->
        <!--            @click.prevent="deleteElement"-->
        <!--            class="mt-10"-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--        >{{ $t("payments.delete") }}-->
        <!--        </v-btn>-->

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Currencies from "../../helpers/Currencies";
import Utils from "../../helpers/Utils";
import DatePicker from "../../components/DatePicker";
import TitleBar from "@/components/TitleBar.vue";
import DynamicElement from "@/helpers/DynamicElement";

export default {
    name: "Details",
    components: {TitleBar, DatePicker, Attachments, Tags},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.payments"),
                to: "/expenses/payments",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("payments.new") : this.$t("payments.edit"),
                to: "/expenses/payments/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.loading = true;
        if (this.$route.params.uuid === "new") {
            let contacts = await Accounting.get("/contacts?fields=name,uuid&per_page=-1&page=1").catch(() => {
            });
            this.contacts = contacts.data.contacts;
        }

        let categories = await Accounting.get("/categories?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.categories = categories.data.categories;

        let accounts = await Accounting.get("/accounts?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.accounts = accounts.data.accounts;
        this.loading = false;

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/expenses/payments/" + this.$route.params.uuid)
                .then(async response => {
                    const data = response.data;
                    this.account = data.account;
                    this.amount = data.amount;
                    this.category = data.category;
                    this.currency_code = data.currency.code;
                    this.currency_rate = data.currency.rate;
                    this.contact = data.contact;
                    this.description = data.description;
                    this.date = data.paid_at;
                    this.payment_method = data.payment_method;
                    this.reference = data.reference;

                    this.contacts = [{
                        name: await DynamicElement.cache("contact", DynamicElement.getCustomer, this.contact),
                        uuid: this.contact
                    }];

                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }


    },
    data: () => {
        return {
            loading: false,
            account: null,
            accounts: [],
            amount: null,
            category: null,
            categories: [],
            currency_code: null,
            currency_rate: null,
            contact: null,
            contacts: [],
            description: null,
            date: null,
            payment_method: null,
            reference: null,
            currencies: Currencies.get()
        };
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("payments.delete_title"),
                text: this.$t("payments.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("payments.delete_yes"),
                cancelButtonText: this.$t("payments.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/expenses/payments/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("payments.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/expenses/payments");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/expenses/payments";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    account: this.account,
                    amount: this.amount,
                    category: this.category,
                    currency_code: this.currency_code,
                    currency_rate: this.currency_rate,
                    contact: this.contact,
                    description: this.description,
                    date: this.date,
                    payment_method: this.payment_method,
                    reference: this.reference
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("payments.added")
                        }]);
                        this.$router.push("/expenses/payments/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("payments.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        contact: {required},
        category: {required},
        currency_code: {required},
        account: {required},
        payment_method: {required},
        date: {required},
        amount: {required}
    },
    computed: {
        contactErrors() {
            const errors = [];
            if (!this.$v.contact.$dirty) return errors;
            !this.$v.contact.required && errors.push(this.$t("payments.contact_required"));
            return errors;
        },
        categoryErrors() {
            const errors = [];
            if (!this.$v.category.$dirty) return errors;
            !this.$v.category.required && errors.push(this.$t("payments.category_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency_code.$dirty) return errors;
            !this.$v.currency_code.required && errors.push(this.$t("payments.currency_required"));
            return errors;
        },
        accountErrors() {
            const errors = [];
            if (!this.$v.account.$dirty) return errors;
            !this.$v.account.required && errors.push(this.$t("payments.account_required"));
            return errors;
        },
        paymentMethodErrors() {
            const errors = [];
            if (!this.$v.payment_method.$dirty) return errors;
            !this.$v.payment_method.required && errors.push(this.$t("payments.payment_method_required"));
            return errors;
        },
        dateErrors() {
            const errors = [];
            if (!this.$v.date.$dirty) return errors;
            !this.$v.date.required && errors.push(this.$t("payments.date_required"));
            return errors;
        },
        amountErrors() {
            const errors = [];
            if (!this.$v.amount.$dirty) return errors;
            !this.$v.amount.required && errors.push(this.$t("payments.amount_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>